import './ExploreContainer.css';
import { useAuth } from '../Common/authContext';

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  const { authInfo } = useAuth()!;

  return (
    <div className="container">
      <strong>{name}</strong>
      <p>Hello {authInfo.user.ProfileName}</p>
      {/* <p>{authInfo.user.access_token}</p> */}
    </div>
  );
};

export default ExploreContainer;
