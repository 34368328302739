export const version = '1.3.6';

export const loginUrl = 'https://service.pmc.org/token';
export const setNotificationDeviceUrl = 'https://service.pmc.org/api/App/setNotificationDevice/';
export const setNotificationsUrl = 'https://service.pmc.org/api/App/setNotifications/';
export const removeNotificationsUrl = 'https://service.pmc.org/api/App/removeNotificationDevice/';
export const setThankedUrl = 'https://service.pmc.org/api/App/setThanked/';
export const logErrorUrl = 'https://service.pmc.org/api/App/log/';
export const getMySummaryUrl = 'https://service.pmc.org/api/App/GetMySummary/';
export const getVolunteerUrl = 'https://service.pmc.org/api/App/GetVolunteerJobs/';
export const getContactsUrl = 'https://service.pmc.org/api/App/Contacts';
export const updateContactsUrl = 'https://service.pmc.org/api/App/UpdateContacts';
export const addContactsUrl = 'https://service.pmc.org/api/App/addContacts';
export const trackApiUrl = 'https://track.pmc.org';

// Set this to false when submitting app for review.
//
export const depositEnabled = true;

//  2023-01-09  hmw Added for convineince

/* interface _user {
    ProfileName: string;
}
export const User = (): _user => {
    let a: _user = { ProfileName: 'whatever' };

    if (localStorage.AUTH) {
        a = JSON.parse(localStorage.AUTH);
    }
    return a;
}
 */

export const notificationTypes = [
    {
        type: "donation"
    },
    {
        type: "history"
    }
]
