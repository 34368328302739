import {
  IonAvatar,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from '@ionic/react';
import {
  openOutline, openSharp
} from 'ionicons/icons';

import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getMenu } from '../Data/menu';
import { version } from '../Common/Global';
import './Menu.css';

import { useAuth } from '../Common/authContext';
import SettingsContext from '../Common/SettingsContext'
import EventContext from '../Common/EventContext';


const Menu: React.FC = () => {
  const menuItems = getMenu().filter(m => m.position === 'side');

  const location = useLocation();

  const { authInfo } = useAuth()!;

  //  Settings Context
  //
  const settings = useContext(SettingsContext);
  const event = settings.data.CurrentEvent;
  const EventDesc = event.EventDesc;
  const SecureUrl = event.SecureUrl;
  const HandbookUrl = event.HandbookUrl;

  const ViewAs = settings.data.ViewAs;

  const badgeUrl = `/assets/img/badges/${event.EventName}.png`

  //  Event Context
  //
  //const TeamName = '(Team Name Here)';
  const { eventData } = useContext(EventContext);
  const { TeamName } = eventData;

  return (
    <IonMenu contentId="main" type="overlay" >
      <IonContent>
        <IonList id="menu-list">
          <IonListHeader>Pan-Mass Challenge</IonListHeader>
          <IonItem lines="none" >
            <IonAvatar slot="start">
              <img src={process.env.PUBLIC_URL + badgeUrl} alt={EventDesc} />
            </IonAvatar>
            <IonLabel>
              {EventDesc}
            </IonLabel>
          </IonItem>

          {menuItems.map((menuItem, index) => {
            //console.log(menuItem.href);

            //  Add on the access token if there is an href and contains access_token
            //
            let href = menuItem.href;
            let title = menuItem.title;
            let isActive = menuItem.IsActive;
            if (href) {
              if (href.indexOf('{SecureUrl}') !== -1) {
                href = menuItem.href.replace('{SecureUrl}', SecureUrl);
                //console.log(href);
              }
              if (href.indexOf('access_token') !== -1) {
                href += authInfo.user.access_token;
                //console.log(href);
              }
              // 2023-09-21 Mdi made handbok based on event
              // If this is the handbook url, make it the event one
              if (title.indexOf('Handbook') !== -1) {
                if (HandbookUrl === '') {
                  isActive = false;
                } else {
                  href = HandbookUrl;
                  title = EventDesc + ' Handbook';
                }
              }
            }
            if (isActive) {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  {menuItem.url ? (
                    <IonItem className={location.pathname === menuItem.url ? 'selected' : ''} routerLink={menuItem.url} routerDirection="none" lines="none" detail={false}>
                      <IonIcon slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
                      <IonLabel>
                        <h2>
                          {menuItem.title}
                        </h2>
                        {ViewAs === 'team' && menuItem.ViewAs === 'team' && <p>{TeamName}</p>}
                      </IonLabel>
                    </IonItem>
                  ) : (
                    <IonItem href={href} target="_blank" lines="none" detail={false}>
                      <IonIcon slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
                      <IonLabel>
                        <h2>
                          {menuItem.title}
                        </h2>
                        {ViewAs === 'team' && menuItem.ViewAs === 'team' && <p>{TeamName}</p>}
                      </IonLabel>
                      <IonIcon slot="end" ios={openOutline} md={openSharp} />
                    </IonItem>
                  )}
                </IonMenuToggle>
              );
            }else{
              return <></>
            }
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>Version : {version}</IonListHeader>
          <IonMenuToggle key='about' autoHide={false}>
            <IonItem routerLink='/About' routerDirection="none" lines="none" detail={false}>
              <IonLabel>About {EventDesc}</IonLabel>
            </IonItem>
            <IonItem routerLink='/Privacy' routerDirection="none" lines="none" detail={false}>
              <IonLabel>Privacy Policy</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
