import { IonContent, IonPage } from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import './FundraisingPage.css';
import '../components/iframe.css';

import TabsHeader from '../components/TabsHeader';
import SettingsContext from '../Common/SettingsContext';

import Spinner from 'react-spinkit';

const FundraisingPage: React.FC = () => {

  const frameref = useRef<HTMLIFrameElement>(null);

  //  Settings Context values
  //
  const settings = useContext(SettingsContext);
  const event = settings.data.CurrentEvent;
  console.log("Event", event);
  const { SecureUrl, EventColor } = event;
  console.log("SecureURL:", SecureUrl);
  const url = `${SecureUrl}/Dashboard/Fundraising/Overview`
  console.log("URL", url);
  //  State Variable for the loading spinner
  //
  const [loading, setLoading] = useState(true);

  const visibleCallback = (isVisible: boolean) => {
    if(isVisible){
      if (frameref.current) {
        frameref.current.src = url;
      }
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible: boolean) => visibleCallback(isVisible)}>
    <IonPage>
      <TabsHeader Title='Fundraising' />
      <IonContent fullscreen>
        <>
          <div className="container">
            {loading ? (
              <Spinner className="loading text-center"
                name="three-bounce"
                color={EventColor}
                fadeIn="none"
              />
            ) : null}
          </div>

          <iframe
            ref={frameref}
            title='frsummary'
            src={url}
            height="100%"
            onLoad={() => { setLoading(false) }}
          />
        </>
      </IonContent>
    </IonPage>
    </VisibilitySensor>
  );
};

export default FundraisingPage;
