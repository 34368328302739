import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';

const NotFoundPage: React.FC = () => {
  //console.log('Not Found');

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Not Found</IonTitle>
          </IonToolbar>
        </IonHeader>    
        <p>These are not the pages that you are looking for.</p>    
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
