import { IonRouterOutlet } from "@ionic/react";
import { Route, Redirect, Switch } from "react-router";

import Page from "../../pages/Page";
import NotFoundPage from "../../pages/NotFoundPage";
import TabsRoot from "../../tabs/TabsRoot";
import AboutAppPage from "../../pages/AboutAppPage";
import ContactList from "../../pages/Contacts";
import Privacy from "../../pages/Privacy";

const MainRouterOutlet: React.FC = () => {
  return (
    <IonRouterOutlet id="main">
      <Switch>
        <Route path="/" exact>
          <Redirect to="/tab/home" />
        </Route>

        <Route path="/page/:name" component={Page} exact />
        <Route path="/tab" component={TabsRoot} />
        <Route path="/About" component={AboutAppPage} />
        <Route path="/Contacts" component={ContactList} />
        <Route path="/Privacy" component={Privacy} />

        <Route path="/NotFound" exact>
          <NotFoundPage />
        </Route>

      </Switch>

    </IonRouterOutlet>
  );
};


export default MainRouterOutlet;
