import { IonToolbar, IonButtons, IonMenuButton, IonButton, IonIcon, IonTitle, IonHeader } from '@ionic/react';
import { cog } from 'ionicons/icons';

interface ContainerProps {
  Title: string;
}

const TabsHeader: React.FC<ContainerProps> = ({ Title }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>{Title}</IonTitle>
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>

        {Title.toLowerCase().includes('home') &&
          <IonButtons slot="end">
            <IonButton id="open-settings-modal">
              <IonIcon slot="icon-only" icon={cog}></IonIcon>
            </IonButton>
          </IonButtons>
        }

      </IonToolbar>
    </IonHeader>
  );
};

export default TabsHeader;
