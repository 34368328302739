import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonText, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import { useRef } from 'react';
import './RegisterModal.css';
import {bicycleOutline, bicycleSharp, accessibilityOutline, accessibilitySharp} from 'ionicons/icons';

import { useAuth } from '../Common/authContext';

export interface RegisterProps {
    event: any;
    isOpen: boolean;
    onDismiss: any;
}

const RegisterModal: React.FC<RegisterProps> = (props) => {
    // props.event = event they are attempting to switch to

    const regmodal = useRef<HTMLIonModalElement>(null);
    const Event = props.event;

    const { authInfo } = useAuth()!;

    if (Event != null) {
        const dte = new Date();
        const POpenDate = new Date(Event.Participant.OpenDate);
        const PCloseDate = new Date(Event.Participant.CloseDate);
        const VOpenDate = new Date(Event.Volunteer.OpenDate);
        const VCloseDate = new Date(Event.Volunteer.CloseDate);

        const RegisterIsOpen = (dte > POpenDate && dte < PCloseDate);
        const VolunteerIsOpen = (dte > VOpenDate && dte < VCloseDate);

        return (
            <IonModal ref={regmodal} isOpen={props.isOpen} onDidDismiss={() => props.onDismiss()} className='registerModal'>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Register</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => regmodal.current.dismiss()}>Cancel</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText className={Event.EventName}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <img src={`${process.env.PUBLIC_URL}/assets/img/badges/${Event.EventName}.png`} alt={Event.EventDesc} />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='regHeader'><IonText>Participant Registration</IonText></IonCol>
                            </IonRow>
                            {RegisterIsOpen ? (<><IonRow>
                                <IonCol>
                                    <IonText>{Event.Participant.OpenText}</IonText>
                                </IonCol>
                            </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton href={Event.Participant.RegisterUrl + "?access_token=" + authInfo.user.access_token} target='_blank' onClick={() => regmodal.current.dismiss()}><IonIcon ios={bicycleOutline} md={bicycleSharp} slot='start'></IonIcon>Register</IonButton>
                                    </IonCol>
                                </IonRow></>) : (<IonRow>
                                    <IonCol>
                                        <IonText>{Event.Participant.ClosedText}</IonText>
                                    </IonCol>
                                </IonRow>)}
                                <IonRow>
                                <IonCol className='regHeader'><IonText>Volunteer Registration</IonText></IonCol>
                            </IonRow>
                                {VolunteerIsOpen ? (<><IonRow>
                                <IonCol>
                                    <IonText>{Event.Volunteer.OpenText}</IonText>
                                </IonCol>
                            </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonButton href={Event.Volunteer.RegisterUrl + "?access_token=" + authInfo.user.access_token} target='_blank' onClick={() => regmodal.current.dismiss()}><IonIcon ios={accessibilityOutline} md={accessibilitySharp} slot='start'></IonIcon>Volunteer</IonButton>
                                    </IonCol>
                                </IonRow></>) : (<IonRow>
                                    <IonCol>
                                        <IonText>{Event.Volunteer.ClosedText}</IonText>
                                    </IonCol>
                                </IonRow>)}

                        </IonGrid>
                    </IonText>
                </IonContent>
            </IonModal>
        )
    } else {
        return (null);
    }
}


export default RegisterModal;