import { IonContent, IonPage } from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import './MobileDepositPage.css';
import '../components/iframe.css';

import TabsHeader from '../components/TabsHeader';
import SettingsContext, { EventList } from '../Common/SettingsContext';

import Spinner from 'react-spinkit';

const MobileDepositPage: React.FC = () => {

  const frameref = useRef<HTMLIFrameElement>(null);

  //  Settings Context values
  //
  const settings = useContext(SettingsContext);
  const event = settings.data.CurrentEvent;
  const { EventColor } = event;
  const SecureUrl = EventList.pmc.SecureUrl;

  let url = '';
  
  if(event.EventName==='wintercycle'){
    url = `${SecureUrl}/Dashboard/Fundraising/MobileDeposit?EventName=Winter-Cycle-Boston`;
  }else{
    url = `${SecureUrl}/Dashboard/Fundraising/MobileDeposit?EventName=${event.EventName}`;
  }
  console.log("URL:", url);
  //  State Variable for the loading spinner
  //
  const [loading, setLoading] = useState(true);

  const visibleCallback = (isVisible: boolean) => {
    if (!isVisible) {
      if (frameref.current) {
        //frameref.current.src = url;
      }
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible: boolean) => visibleCallback(isVisible)}>
      <IonPage>
        <TabsHeader Title='Mobile Deposit' />
        <IonContent fullscreen>
          <>
            <div className="container">
              {loading ? (
                <Spinner className="loading text-center"
                  name="three-bounce"
                  color={EventColor}
                  fadeIn="none"
                />
              ) : null}
            </div>

            <iframe
              ref={frameref}
              title='mobiledeposit'
              src={url}
              height="100%"
              onLoad={() => { setLoading(false) }}
              allow='autoplay;camera;microphone'
            />
          </>
        </IonContent>
      </IonPage>
    </VisibilitySensor>
  );
};

export default MobileDepositPage;
