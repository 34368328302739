import { createContext } from "react";
import { Summary } from '../Models/Summary';
import { SummaryRequest } from '../Models/api';
import { loadSummary, loadVolunteer } from '../Common/api';
import { VolunteerData } from "../Models/volunteer";


//  TeamName, IsCaptain and EventList are all convenience fields 
//  and will be updated from the return of EventSummary
//
export type EventContextType = {
  Events: string,
  TeamName: string,
  IsTeamCaptain: boolean,
  summaryData: Summary,
  volunteerData?: VolunteerData
}

export const EventDefaultValues = {
  eventData: {
    Events: '',
    TeamName: '',
    IsTeamCaptain: false,
    summaryData: {
      EventList: '',
      RegisteredEvents: '',
      TotalRaised: 0,
      TYears: 0,
      PYears: 0,
      VYears: 0,
      Commitment: 0,
      Raised: 0,
      TeamRaised: 0,
      IsRider: false,
      IsVolunteer: false,
      IsCaptain: false,
      TeamName: '',
      RestrictedFundDesc: '',
      EventOptionDesc: '',
      TransOptionDesc: '',
      LodgingOptionDesc: '',
      FridayLodgingOptionDesc: '',
      SaturdayLodgingOptionDesc: '',
      RoomInfo: '',
      ProfilePhoto: '',
      Goal: 0,
      TeamProfilePhoto: '',
      TeamGoal: 0,
      MobileDeposit: false
    },
    volunteerData: {
      Jobs: [{
        VolReqID: 0,
        SiteDesc: '',
        CrewDesc: '',
        PositionDesc: '',
        TimeSlot: '',
        description: ''
      }]
    }
  } as EventContextType,
  setEventData: (state: EventContextType) => { }
};

const EventContext = createContext(EventDefaultValues);

export const LoadSummaryData = async (eventName: string, mainID: string, access_token: string) => {

  const summaryRequest: SummaryRequest = {
    eventName: eventName,
    mainID: mainID
  }

  return loadSummary(summaryRequest, access_token).then(response => response)
}

export const LoadVolunteerData = async (eventName: string, mainID: string, access_token: string) => {
  const summaryRequest: SummaryRequest = {
    eventName: eventName,
    mainID: mainID
  }

  return loadVolunteer(summaryRequest, access_token).then(response => response).catch(error => error);
}




export default EventContext;
