import { PushNotifications } from '@capacitor/push-notifications';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { logError } from './utils';
import axios from 'axios'

import { setNotificationDeviceUrl, notificationTypes, setNotificationsUrl } from './Global';

interface NotificationItem {
    RecordType: string;
    Enabled: string;
}

export const checkNotificationsDisabled = async () => {
    //const r = await PushNotifications.checkPermissions();
    //console.log("CHECK: ", r)
    /*const userAuthData = localStorage.getItem("AUTH") + "";
    const userData = JSON.parse(userAuthData);
    let message = `User disabled notifications - ${userData.Main_ID} - ${userData.userName}
    
    ${localStorage.getItem("deviceInfo")}`;
    logError(message, 'NotificationsDisabled');*/
}

/*const getDeviceInfo = async () => {
    let id = await Device.getId();
    const did = id.uuid;
    localStorage.setItem("deviceId", did);
    let deviceData = await Device.getInfo();
    const DeviceInfo = {
        os: deviceData.operatingSystem,
        osVersion: deviceData.osVersion,
        name: deviceData.name,
        model: deviceData.model
    }
    localStorage.setItem("deviceInfo", JSON.stringify(DeviceInfo));
}*/

const getToken = () => {
    FirebaseMessaging.getToken().then((r) => {
        localStorage.setItem("FCMToken", r.token);
        registerWithPMC();
    })
}

const registerTypes = (config: any) => {
    //console.log("registerTypes-------------------------------------");
    let items: NotificationItem[] = [];
    let notificationPost = {
        Target: "FCM2",
        DeviceID: localStorage.getItem("deviceId"),
        AWSToken: "",
        items: items
    }

    notificationTypes.forEach(type => {

        const notificationItem = {
            RecordType: type.type,
            Enabled: "yes"
        }

        notificationPost.items.push(notificationItem);
    });

    axios.post(setNotificationsUrl, notificationPost, config).then(response => {

        if (response.data.Status !== "Success") {
            logError(JSON.stringify(response), 'setNotifications');
            localStorage.removeItem("FCMToken");
        }

    }).catch(error => {
        logError(JSON.stringify(error), 'setNotifications');
        localStorage.removeItem("FCMToken");
    });
}

const registerWithPMC = () => {
    // Call setNotificationDevice
    //
    const devicePost = {
        DeviceID: localStorage.getItem("deviceId"),
        RegistrationID: localStorage.getItem("FCMToken"),
        DeviceInfo: localStorage.getItem("deviceInfo")
    }
    const userAuthData = localStorage.getItem("AUTH") + "";
    const userData = JSON.parse(userAuthData);

    let config = {
        headers: { Authorization: `Bearer ${userData.access_token}` }
    }

    axios.post(setNotificationDeviceUrl, devicePost, config).then(response => {

        if (response.data.Status === "Success") {
            // Call setNotification for each type
            //
            registerTypes(config);
        } else {
            // Registration failed so clear the token to try again later
            // TBD what else needs to be done
            //
            logError(JSON.stringify(response), 'setNotificationDevice');
            localStorage.removeItem("FCMToken");
        }
    }).catch(error => {
        logError(JSON.stringify(error), 'setNotificationDevice');
        localStorage.removeItem("FCMToken");
    });
}

export const setUpNotifications = async () => {
    // if we don't have a token we need to get one
    //
    if (localStorage.getItem("FCMToken") === null) {

        //await getDeviceInfo();

        try {
            const resp = await PushNotifications.requestPermissions();
            if (resp.receive !== "denied") {
                await PushNotifications.register();
                
                getToken();
            }
        } catch {
            //console.log("No Notifications");
        }
    }
}