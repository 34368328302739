import { IonContent, IonFab, IonFabButton, IonIcon, IonPage, IonRefresher, IonRefresherContent, RefresherEventDetail, useIonViewDidEnter } from '@ionic/react';
import { useContext, useRef, useState } from 'react';

import './HomePage.css';
import '../components/iframe.css';

import { useAuth } from "../Common/authContext";
import SettingsContext from '../Common/SettingsContext'
import EventContext from '../Common/EventContext';

import TabsHeader from '../components/TabsHeader';
import SettingsModal from '../components/SettingsModal';
import RegisterModal from '../components/RegisterModal';
import SummaryComponent from '../components/SummaryComponent';
import { Share } from '@capacitor/share';
import { shareOutline, shareSharp, chevronDownCircleOutline } from "ionicons/icons";
import { checkNotificationsDisabled } from '../Common/Notification';


const HomePage: React.FC = () => {
  //  Auth Context
  //
  const { authInfo } = useAuth()!;
  const access_token = authInfo.user.access_token;

  //  Event Context
  //
  const { eventData } = useContext(EventContext);
  const { TeamName } = eventData;

  //  Sharing allowed?
  const [sharingAllowed, setSharingAllowed] = useState(true);

  //  Settings Context values
  //
  const settings = useContext(SettingsContext);
  const ViewAs = settings.data.ViewAs;
  const event = settings.data.CurrentEvent;
  // eslint-disable-next-line
  const { EventDate, EventDesc, SecureUrl, PhotoUrl } = event
  const title = ViewAs === 'team' ? 'Team Home' : 'Home';

  const [registerOpen, setRegisterOpen] = useState(false);
  const [clickedEvent, setClickedEvent] = useState();

  const [refreshCount, setRefreshCount] = useState(0);
  const [showToast, setShowToast] = useState(false);

  //  Once logged in, no need to go back to AppLogin page
  //
  let url = `${SecureUrl}/Account/AppLogin?access_token=${access_token}&width=` + window.innerWidth;

  //  If viewing as the team change to the Team Overview page
  //
  if (ViewAs === 'team') {
    url = `${SecureUrl}/Dashboard/Team`;
  }

  //  Reset the iframe JIC a link was clicked
  const frameref = useRef<HTMLIFrameElement>(null);

  const prepareShare = () => {

    // Title of share
    //
    let title = "";
    if (ViewAs === 'self') {
      title = authInfo.user.ProfileName;

    } else {
      if (TeamName != null) {
        title = TeamName.startsWith('Team') ? TeamName : 'Team ' + TeamName;
      }
    }

    if (!title.endsWith('s')) {
      title = title + "'s";
    }

    title = title + ' ' + EventDesc;

    // text
    //
    const text = 'Please support my ' + EventDesc + '.';

    // Url of the profile page
    //
    const url = 'https://Profile.' + event.EventName + '.org/' + authInfo.user.userName;

    // Dialog Title
    //
    const dialogTitle = 'Share My ' + EventDesc + '.';

    return {
      title: title,
      text: text,
      url: url,
      dialogTitle: dialogTitle
    };


  }

  // Trigger a refresh
  // Refresh is caused by setting the refresh date
  //
  const doRefresh = () => {
    //console.log('DoRefresh');

    setShowToast(true);
    const val = refreshCount;
    setRefreshCount(val + 1);
  }

  //  2023-02-11  hmw Added Pull to refresh
  //
  const handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    doRefresh();
    e.detail.complete();
  }

  // handle sharing
  //
  const shareIt = async () => {
    const shareData = prepareShare();

    await Share.share({
      title: shareData.title,
      text: shareData.text,
      url: shareData.url,
      dialogTitle: shareData.dialogTitle
    });

  }

  const checkSharing = async () => {
    const ShareAllowed = await Share.canShare();
    setSharingAllowed(ShareAllowed.value);
  }

  const promptToRegister = (Event) => {
    setClickedEvent(Event);
    setRegisterOpen(true);
  }

  const registerDismiss = () => {
    setRegisterOpen(false);
  }

  useIonViewDidEnter(()=>{
    checkNotificationsDisabled();
    checkSharing();
  });

  return (


    <IonPage>
      <TabsHeader Title={title} />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing...">
          </IonRefresherContent>
        </IonRefresher>

        <SummaryComponent
          refreshCount={refreshCount}
          showToast={showToast}
        />

        {/* 2023-02-11  hmw Modified to just have a sharing FAB
        {sharingAllowed ? (
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton>
              <IonIcon ios={chevronUpCircleOutline} md={chevronUpCircleSharp}></IonIcon>
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton>
                <IonIcon ios={shareOutline} md={shareSharp} onClick={() => shareIt()}></IonIcon>
              </IonFabButton>
              <IonFabButton>
                <IonIcon ios={refreshCircleOutline} md={refreshCircleSharp} onClick={() => doRefresh()}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </IonFab>
        ) : (
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton>
              <IonIcon ios={refreshCircleOutline} md={refreshCircleSharp} onClick={() => doRefresh()}></IonIcon>
            </IonFabButton>
          </IonFab>
        )}
        */}

        {sharingAllowed ? (
          <IonFab slot="fixed" vertical="bottom" horizontal="end">
            <IonFabButton>
              <IonIcon ios={shareOutline} md={shareSharp} onClick={() => shareIt()}></IonIcon>
            </IonFabButton>
          </IonFab>
        ) : (null)}

        {/* Testing Buttons */}
        {/*
          <div>
            <IonButton size='small' onClick={() => { setTeamName('My Team Name'); }}>Set Team Name</IonButton>
            <IonButton size='small' onClick={() => { setTeamName(''); }}>Clear Team Name</IonButton>
            <IonButton size='small' onClick={() => { setIsTeamCaptain(true); }}>Is IsTeamCaptain</IonButton>
            <IonButton size='small' onClick={() => { setIsTeamCaptain(false); }}>Not IsTeamCaptain</IonButton>
            <IonButton size='small' onClick={() => { setEvents('pmc,unpaved,wintercycle'); }}>All Events</IonButton>
            <IonButton size='small' onClick={() => { setEvents('pmc'); }}>Just PMC</IonButton>
            <IonButton size='small' onClick={() => { setEvents(''); }}>No Events</IonButton>
          </div>
  */}
        <iframe ref={frameref} title='home' src={url} height="100%" hidden={true} />

        <SettingsModal
          promptToRegister={promptToRegister} />

        <RegisterModal
          event={clickedEvent}
          isOpen={registerOpen}
          onDismiss={registerDismiss} />

      </IonContent>
    </IonPage>


  );
};

export default HomePage;
