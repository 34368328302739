import {
  accessibilityOutline, accessibilitySharp,
  bicycleOutline, bicycleSharp,
  personOutline, personSharp,
  peopleOutline, peopleSharp,
  barChartOutline, barChartSharp,
  speedometerOutline, speedometerSharp,
  newspaperOutline, newspaperSharp,
  walkOutline, walkSharp,
  logoFacebook,
  cardOutline,
  cardSharp,
  lockClosedOutline,
  lockClosedSharp,
} from 'ionicons/icons';

import { menuItem } from '../Models/menu';

import {EventList} from '../Common/SettingsContext';

const menuItems: menuItem[] = [
/*     {
        title: 'Home',
        url: '/tab/Home',
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
 */      {
    title: 'Dashboard',
    href: '{SecureUrl}Account/AppLogin?returnUrl=home&access_token=',
    iosIcon: speedometerOutline,
    mdIcon: speedometerSharp,
    IsActive: true,
    position: 'side'
  },
  {
    title: 'Import Contacts',
    url: '/Contacts',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    IsActive: true,
    position: 'side'
  },
  {
    title: 'Facebook Fundraiser',
    /*url: '/page/Facebook',*/
    href: '{SecureUrl}/Dashboard/Fundraising/Facebook?access_token=',
    iosIcon: logoFacebook,
    mdIcon: logoFacebook,
    IsActive: true,
    ViewAs: 'team',
    position: 'side'
  },
  {
    title: 'Rider',
    url: '/page/Rider',
    href: '/Dashboard/Event/Rider',
    iosIcon: bicycleOutline,
    mdIcon: bicycleSharp,
    IsActive: true,
    position: 'side'
  },
  {
    title: 'Volunteer',
    url: '/page/Volunteer',
    href: '/Dashboard/Event/Volunteer',
    iosIcon: accessibilityOutline,
    mdIcon: accessibilitySharp,
    IsActive: true,
    position: 'side'
  },
  {
    title: 'Training',
    url: '/page/Training',
    iosIcon: walkOutline,
    mdIcon: walkSharp,
    IsActive: false,
    position: 'side'
  },
  {
    title: 'About You',
    url: '/page/About',
    href: `${EventList.pmc.SecureUrl}/Dashboard/AboutYou`,
    iosIcon: personOutline,
    mdIcon: personSharp,
    IsActive: true,
    position: 'side'
  },
  {
    title: 'Event History',
    url: '/page/History',
    href: `${EventList.pmc.SecureUrl}/History`,
    iosIcon: barChartOutline,
    mdIcon: barChartSharp,
    IsActive: true,
    //ViewAs:'team',
    position: 'side'
  },
  {
    title: '2024 Handbook',
    href: 'https://view.publitas.com/pan-mass-challenge/pmc-handbook-2023/page/1',
    iosIcon: newspaperOutline,
    mdIcon: newspaperSharp,
    IsActive: true,
    position: 'side'
  },
  //  Settings menu
  //
  {
    title: 'Account',
    url: '/page/Account',
    href: '/Dashboard/Home/Account',
    iosIcon: personOutline,
    mdIcon: personSharp,
    IsActive: true,
    position: 'settings'
  },
  {
    title: 'Password',
    url: '/page/Password',
    href: '/Manage/ChangePassword',
    iosIcon: lockClosedOutline,
    mdIcon: lockClosedSharp,
    IsActive: true,
    position: 'settings'
  }, {
    title: 'Card on File',
    url: '/page/CreditCard',
    href: '/Manage/CreditCard',
    iosIcon: cardOutline,
    mdIcon: cardSharp,
    IsActive: false,
    position: 'settings'
  },

];

export const getMenu = () => menuItems.filter(m => m.IsActive)