import axios from 'axios'
import { logError } from './utils';
import { setThankedUrl, getMySummaryUrl, getContactsUrl, updateContactsUrl, addContactsUrl, getVolunteerUrl } from './Global';
import { Thanked, SummaryRequest } from '../Models/api';
import { Summary } from '../Models/Summary';
import { PmcContact, PostContactList } from '../Models/contacts';
import { VolunteerData } from '../Models/volunteer';

const GetConfig = (token: string) => {
    let config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    return config;
}

export const setThanked = (thanked: Thanked, token: string) => {

    axios.post(setThankedUrl, thanked, GetConfig(token)).then(response => {
        
        if (response.data !== "success") {
            // Thank failed
            // TBD what else needs to be done
            //
            logError(JSON.stringify(thanked) + ' ' + JSON.stringify(response), 'setThanked');
        }
    }).catch(error => {
        logError(JSON.stringify(error), 'setThanked');
    });
}

export const loadSummary = async (summaryRequest: SummaryRequest, token: string): Promise<Summary> => {
    //console.log("Summary Request:", summaryRequest);
    let theSumm: any = {
        EventList: null
    };
    await axios.post(getMySummaryUrl, summaryRequest, GetConfig(token)).then(response => {
        theSumm = response.data;
    }).catch(error => {
        if(error.response!==undefined){
            if(error.response.status===401){
                theSumm.EventList = '401';
            }
        }
    });
    return theSumm;
};

export const loadVolunteer = async(summaryRequest: SummaryRequest, token: string): Promise<VolunteerData> => {
    return await axios.post(getVolunteerUrl, summaryRequest, GetConfig(token)).then(response => response.data).catch(error => error);
}

export const loadMySummary = (summaryRequest: SummaryRequest, token: string): Summary => {
    //console.log("Summary Request:", summaryRequest);
    let ret: Summary;
    axios.post(getMySummaryUrl, summaryRequest, GetConfig(token)).then(response => {
        ret = response.data;
}   ).catch(error => error);

    return ret;
};

export const loadMyContacts = async (token: string) => {
    return await axios.get(getContactsUrl, GetConfig(token)).then(response => response.data).catch(error => error);
}

export const updateContacts = async (token: string, contacts: PmcContact[]) => {
    return await axios.post(updateContactsUrl, contacts, GetConfig(token)).then(response => response.data).catch(error => error);
}

export const addContacts = async (token: string, contacts: PostContactList) => {
    return await axios.post(addContactsUrl, contacts, GetConfig(token)).then(response => response.data).catch(error => error);
}
