import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonLoading,
  //IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
  useIonViewDidEnter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect, useState } from 'react';
import { CapacitorUpdater } from '@capgo/capacitor-updater'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import Menu from './components/Menu';
import LoginPage from './pages/LoginPage';
import RouterOutlet from "./components/router/MainRouterOutlet";

import { SplashScreen } from '@capacitor/splash-screen';

//  Setup the State contexts
//
import { useAuth } from './Common/authContext';
import SettingsContext, { SettingsContextType, SettingsDefaultValues } from './Common/SettingsContext'
import EventContext, { EventDefaultValues, EventContextType } from './Common/EventContext';

// Helpers
import { setUpNotifications } from './Common/Notification'
import NotificationListener from './components/NotificationListener';

setupIonicReact();

const App: React.FC = () => {

  //  Setup the Auth and Settings Context
  //
  const { authInfo, initialize } = useAuth()!;

  //  Configure the Settings Context
  //
  const [data, setData] = useState(SettingsDefaultValues.data);
  const changeSettings = (value: SettingsContextType) => setData(data && value);

  //  Configure the EventSummary Context
  //
  const [eventData, setEventData] = useState(EventDefaultValues.eventData);
  const changeEvent = (value: EventContextType) => setEventData(eventData && value);

  useEffect(() => {
    !authInfo?.initialized && (async () => await initialize())();
  }, [authInfo, initialize]);

  const showSplash = async () => {
    await SplashScreen.show({
      showDuration: 1000,
      autoHide: true,
    });
  }

  const setMainId = () => {
    const userAuthData = localStorage.getItem("AUTH") + "";
    //debugger;
    if(userAuthData!==null && userAuthData !== undefined){
      const userData = JSON.parse(userAuthData);
      
      if(userData!==null&&userData!==undefined){
        if(userData.Main_ID!==null&&userData.Main_ID!==undefined){
          CapacitorUpdater.setCustomId({ customId: userData.Main_ID });
        }
      }
    }
  }

  useEffect(() => {
    showSplash();
  }, []);

  useIonViewDidEnter(() => {
    
  });

  if (!authInfo || !authInfo.initialized) {
    return (
      <IonApp>
        <IonLoading isOpen={true} />
      </IonApp>
    );
  } else {
    if(authInfo?.loggedIn===true){
      setMainId();
      setTimeout(() => {
        setUpNotifications();
      }, 2000);
      
    }
    return (
      <IonApp className={data.CurrentEvent.EventName}>
        <>
          {authInfo?.loggedIn === true ? (
            <EventContext.Provider value={{ eventData, setEventData: changeEvent }}>
              <SettingsContext.Provider value={{ data, setData: changeSettings }}>
                <IonReactRouter>
                  <IonSplitPane contentId="main">
                    <Menu />
                    <NotificationListener />
                    <RouterOutlet />
                  </IonSplitPane>
                </IonReactRouter>
              </SettingsContext.Provider>
            </EventContext.Provider>
          ) : (
            <IonReactRouter>
              <Route path="/login" component={LoginPage} exact />
              <Redirect from="/" to="/login" exact />
            </IonReactRouter>
          )}
        </>
      </IonApp>
    )
  }
};

export default App;
