import { IonContent, IonPage } from '@ionic/react';
import { useContext, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import './ProfilePage.css';
import '../components/iframe.css';

import TabsHeader from '../components/TabsHeader';
import SettingsContext from '../Common/SettingsContext';

import Spinner from 'react-spinkit';

const ProfilePage: React.FC = () => {
  const frameref = useRef<HTMLIFrameElement>(null);

  //  Settings Context values
  //
  const settings = useContext(SettingsContext);
  const ViewAs = settings.data.ViewAs;
  const event = settings.data.CurrentEvent;
  const { SecureUrl, EventColor } = event;

  let url = `${SecureUrl}/Profile`;
  const title = ViewAs === 'team' ? 'Team Profile' : 'Profile';
  console.log("URL", url);
  //  State Variable for the loading spinner
  //
  const [loading, setLoading] = useState(true);

  //  If viewing as team add the parameter on to the url
  //
  if (ViewAs === 'team') {
    url += '?ProfileType=Team';
  }

  const visibleCallback = (isVisible: boolean) => {
    if(isVisible){
      if (frameref.current) {
        frameref.current.src = url;
      }
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible: boolean) => visibleCallback(isVisible)}>
    <IonPage>
      <TabsHeader Title={title} />
      <IonContent fullscreen>
        <>
          <div className="container">
            {loading ? (
              <Spinner className="loading text-center"
                name="three-bounce"
                color={EventColor}
                fadeIn="none"
              />
            ) : null}
          </div>

          <iframe
            ref={frameref}
            title='profile'
            src={url}
            height="100%"
            onLoad={() => { setLoading(false) }}
            allow='autoplay;camera;microphone'
          />
        </>
      </IonContent>
    </IonPage>
    </VisibilitySensor>
  );
};

export default ProfilePage;
