import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { useContext } from 'react';
import parse from 'html-react-parser';

import './AboutAppPage.css';
import SettingsContext from '../Common/SettingsContext';

const AboutAppPage: React.FC = () => {
  //  Settings Context
  //
  const settings = useContext(SettingsContext);
  const event = settings.data.CurrentEvent;
  const EventDesc = event.EventDesc;
  const About = event.About;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/tab/Home' />
          </IonButtons>
          <IonTitle>About {EventDesc}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <div className='about-container'>
          {parse(About)}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AboutAppPage;
