import {
  IonContent,
  IonPage,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonLabel,
  IonInput,
  IonIcon,
  IonItem,
  useIonAlert //  2023-01-07  hmw Converted to using an alert
} from "@ionic/react";

import React, { useRef, useState } from "react";

// Auth
import { RouteComponentProps } from "react-router";
import { useAuth } from "../Common/authContext";
import './LoginPage.css';

import { eye, eyeOff } from "ionicons/icons";
import { version } from '../Common/Global';



const LoginPage: React.FC<RouteComponentProps> = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const emailRef = useRef<HTMLIonInputElement>(null);
  const [emailError, setEmailError] = useState(false);
  const passRef = useRef<HTMLIonInputElement>(null);
  const [passError, setPassError] = useState(false);
  const [passType, setPassType] = useState<any>('Password');
  //  2023-01-07  hmw Implemented Toggle Password Field
  const [passIcon, setPassIcon] = useState<any>(eye);


  //  2023-01-07  hmw converted to using an alert
  const [presentAlert] = useIonAlert();

  const validEmail = new RegExp(/^\S+@\S+\.\S+$/);
  const validPMCID = (val: string) => {
    const idPattern = new RegExp('^[a-zA-Z]+[0-9]+$');
    if (val.length !== 6) {
      return false;
    }
    return idPattern.test(val);
  }
  let { logIn } = useAuth()!;

  const doLogin = async () => {
    validate();
  }

  const validate = async () => {
    let email = "";
    let pass = "";

    //  2023-01-07  hmw Added local var becuase this is Async and sate vars are not updated by the time to check for posting
    //
    let okToPost = true;

    const emailValue = emailRef.current?.value + "";

    if (emailValue !== "") {
      email = emailValue;
      const isEmail = validEmail.test(emailValue);
      const isPMCID = validPMCID(emailValue);

      if (!isEmail && !isPMCID) {
        setEmailError(true);
        okToPost = false;
      } else {
        setEmailError(false);
      }

    } else {

      setEmailError(true);
      okToPost = false;
    }

    const passValue = passRef.current?.value + "";

    if (passValue === "") {
      setPassError(true);
      okToPost = false;
    } else {
      pass = passValue;
      setPassError(false);
    }

    //  2023-01-07  hmw Early exit if there were issues
    //
    if (!okToPost) return;

    //  2023-01-07  hmw Moved from above
    //
    setShowLoading(true);

    if (!emailError && !passError) {

      try {
        let gotIn = await logIn(email, pass);

        setShowLoading(false);
        if (gotIn) {
          history.replace("/");
        } else {
          showToast("Incorrect email, PMC ID or password.");
        }
      } catch (error) {
        setShowLoading(false);
        showToast(error + "");

      }
    } else {
      setShowLoading(false);
    }
  }

  const showToast = (message: string) => {
    //  2023-01-07  hmw Converted to using an alert
    //
    presentAlert({
      header: 'Login Failure',
      message: message,
      buttons: ['OK'],
    })

  }

  //  2023-01-07  hmw Implemented Toggle Password Field
  const togglePassIcon = () => {
    setPassType(passType === 'Password' ? 'Text' : 'Password');
    setPassIcon(passIcon === eyeOff ? eye : eyeOff);
  }

  

  return (
    <IonPage>
      <IonContent fullscreen className="login">
        <IonLoading
          message='Loading...'
          isOpen={showLoading}
        />

        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol size="12" className="ion-text-center">
              <h3>Pan-Mass Challenge</h3>
              <img src={process.env.PUBLIC_URL + '/assets/img/badges/pmc.png'} alt="PMC Event" width={150} />
            </IonCol>
          </IonRow>

          <IonRow className="ion-margin-top ion-padding-top">
            <IonCol size="12">
              <IonItem >
                <IonLabel position="floating">Enter your email or PMC ID.</IonLabel>
                <IonInput ref={emailRef} type='email' placeholder='someone@me.com' clearInput={true} />
              </IonItem>
              {emailError && <div className="animate__animated animate__bounceIn error">Please enter a valid email or PMC ID.</div>}

              <IonItem>
                <IonLabel position="floating">Enter your Password.</IonLabel>
                <IonInput ref={passRef} placeholder="********" type={passType}></IonInput>
                <IonIcon slot="end" icon={passIcon} onClick={() => togglePassIcon()}></IonIcon>
              </IonItem>
              {passError && <div className="animate__animated animate__bounceIn error">Please enter your password.</div>}


              <IonButton className="custom-button" color='danger' expand="block" onClick={doLogin}>Login</IonButton>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-right ion-justify-content-center">
            <IonCol size="6" className="ion-text-left">
              <p>
                <a href="https://secure.pmc.org/Account/ForgotEgiftID" target="_blank" rel="noreferrer">Forgot PMC ID</a>
              </p>
            </IonCol>
            <IonCol size="6" className="ion-text-right">
              <p>
                <a href="https://secure.pmc.org/Account/ForgotPassword" target="_blank" rel="noreferrer">Forgot Password</a>
              </p>
            </IonCol>
          </IonRow>
          <IonRow className="ion-text-right ion-justify-content-center">
            <IonCol size="12" className="version">
                Version : {version}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
