import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton } from '@ionic/react';
import { useLocation, useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import VisibilitySensor from 'react-visibility-sensor';

import { getMenu } from '../Data/menu';
import { useContext, useRef, useState } from 'react';
import SettingsContext from '../Common/SettingsContext';

import Spinner from 'react-spinkit';

const Page: React.FC = () => {

  const { name } = useParams<{ name: string; }>();
  const loc = useLocation();
  //console.log(url.pathname);

  const menuItem = getMenu().find(m => m.url === loc.pathname);

  //  State Variable for the loading spinner
  //
  const [loading, setLoading] = useState(true);

  //  Settings Context values
  //
  const settings = useContext(SettingsContext);
  const ViewAs = settings.data.ViewAs;
  const event = settings.data.CurrentEvent;
  const { SecureUrl, EventColor } = event;

  let url = `${SecureUrl}${menuItem.href}`;

  //  2023-01-30  hmw If the href starts with https:// then no need to tack on teh SecureUrl
  //
  if (menuItem.href.startsWith('https://')) {
    url = menuItem.href;
  }

  //  This is a complete hack
  //  if the name is Facebook and viewing as as the team replace out Fundraising with team in the href
  //
  if (name.toLowerCase() === 'facebook' && ViewAs === 'team') {
    url = url.replace('Fundraising', 'Team');
  }

  //  Reset the iframe JIC a link was clicked
  const frameref = useRef<HTMLIFrameElement>(null);
  const visibleCallback = (isVisible: boolean) => {
    //console.log(isVisible);

    if (!isVisible) {
      if (frameref.current) {
        //frameref.current.src = url;
      }
    }
  }

  return (
    <VisibilitySensor onChange={(isVisible: boolean) => visibleCallback(isVisible)}>

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref='/tab/Home' />
            </IonButtons>
            <IonTitle>{menuItem.title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          {menuItem.href ? (
            <>
              <div className="container">
                {loading ? (
                  <Spinner className="loading text-center"
                    name="three-bounce"
                    color={EventColor}
                    fadeIn="none"
                  />
                ) : null}
              </div>

              <iframe
                ref={frameref}
                title={menuItem.title}
                src={url}
                height="100%"
                onLoad={() => { setLoading(false) }}
              />

            </>
          ) : (
            <ExploreContainer name={name} />
          )}

        </IonContent>
      </IonPage>
    </VisibilitySensor>
  );
};

export default Page;
