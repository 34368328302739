import { useCallback, useEffect, useRef, useState } from 'react';
import { Contacts } from '@capacitor-community/contacts';
import { Contact, CleanContact, PhoneNumber, PmcContact, PostContact, PostContactList } from '../Models/contacts';
import { IonItem, IonGrid, IonRow, IonCol, IonCheckbox, IonAvatar, IonLabel, IonBackButton, IonButtons, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar, IonInfiniteScroll, IonInfiniteScrollContent, IonSearchbar, IonFooter, IonButton, IonLoading, useIonAlert } from '@ionic/react';
import './Contacts.css';
import { loadMyContacts, updateContacts, addContacts } from '../Common/api';
import { useAuth } from '../Common/authContext';
import { logError } from '../Common/utils';

const ContactsList: React.FC = () => {
  // List of cleaned Contatcs
  //
  let cleanContacts: CleanContact[] = [];

  // List of selected contacts to be imported
  //
  const [contactsToAdd, setContactsToAdd] = useState<CleanContact[]>([]);

  // Loader
  //
  const [showLoading, setShowLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading Contacts ...');

  const importButton = useRef<HTMLIonButtonElement>();
  const [importDisabled, setImportDisabled] = useState(true);

  const [SelectLabel, setSelectAll] = useState("Select All");

  // full list of contatcs
  //
  const [ContactFullList, setContactFullList] = useState([]);

  const [presentAlert] = useIonAlert();
  const [presentDoneAlert] = useIonAlert();

  let matchedContacts: PmcContact[];

  const { authInfo } = useAuth()!;
  const access_token = authInfo.user.access_token;

  const [contacts, setContacts] = useState<CleanContact[]>(() => [])

  // Used to update after import
  //
  const [, updateState] = useState(null);
  const forceUpdate = useCallback(() => updateState({}), []);
  // Search
  //
  const [searchText, setSearchText] = useState("");
  const searchBar = useRef<HTMLIonSearchbarElement>(null);

  const handleSearchChange = async (val: string) => {
    await setSearchText(val);
  }

  // Load more method for loading more contacts as they scroll
  //
  const loadMore = useCallback(() => {
    setShowLoading(true);
    setContacts((contacts) => [...contacts, ...getContacts(cleanContacts, 100, contacts.length)]);
    setShowLoading(false);
    // eslint-disable-next-line
  }, [setContacts])

  // Sorter to sort contacts by name
  //
  const userSorter = (a, b) => {
    if (a.sortName < b.sortName) {
      return -1
    }
    if (a.sortName > b.sortName) {
      return 1
    }
    return 0
  }

  const loadContactsDevice = async () => {
    setShowLoading(true);

    // Load Contacts from PMC
    //
    loadMyContacts(access_token).then(response => {
      // Process these contacts with the ones from Device
      //
      processContacts(response);
    }).catch(error => {
      setShowLoading(false);

      //  2021-02-11  hmw Added Alert
      presentAlert({
        header: 'Error accessing contacts',
        message: error,
        buttons: ['OK'],
      })

    });
  }

  const processContacts = async (pmccontacts: PmcContact[]) => {
    // Get contacts from Device and check for matches
    //
    matchedContacts = [];
    const projection = {
      name: true,
      phones: true,
      postalAddresses: true,
      organization: true,
      emails: true,
      image: true,
    };

    await Contacts.getContacts({ projection }).then((data) => {
      // Loop through device contatcs and clean them up fo use on screen
      //
      //console.log(JSON.stringify(data.contacts.slice(0,10)));
      //debugger;

      data.contacts.forEach(c => {

        const cc = cleanContact(c, pmccontacts);

        cleanContacts.push(cc);
        cleanContacts.sort(userSorter);
        //console.log(cc);
      });

      // Set the full list to be used for getting more later
      //
      setContactFullList(cleanContacts);

      // Set the contacts to be displayed (100 at a time)
      //
      setContacts((contacts) => [...contacts, ...getContacts(cleanContacts, 100, contacts.length)])

      // If there are any matches that the externalID doesn't match, update them
      //
      postContactUpdates(matchedContacts);

    }).catch(error => {
      console.log("ERROR:", error);

      setShowLoading(false);

      //  2021-02-11  hmw Added Alert
      presentAlert({
        header: 'Error accessing contacts',
        message: error,
        buttons: ['OK'],
      })

    });


  }

  const postContactUpdates = (updatedContacts: PmcContact[]) => {
    // get the contacts that reuire update
    //
    const contactsToUpdate = updatedContacts.filter(c => c.requiresUpdate === true);
    //console.log("Update Contacts: ", contactsToUpdate);
    // Post update to server
    //
    if (contactsToUpdate.length > 0) {
      updateContacts(access_token, contactsToUpdate).then(response => {

        setShowLoading(false);
      }).catch(error => {
        console.log(error);
        setShowLoading(false);
      })
    } else {
      setShowLoading(false);
    }
  }

  const getContacts = (fulllist: CleanContact[], length, startIndex = 0) => {

    if (searchBar.current.value !== "" && searchBar.current.value?.length >= 3) {
      //console.log("Here");
      const filtered = fulllist.filter(c => c.displayName?.toLowerCase().indexOf(searchBar.current.value.toLowerCase()) !== -1 && c.displayName?.toLowerCase() !== "");
      return filtered.slice(startIndex, startIndex + length);
    } else {
      //console.log("Not here");
      // Get the next 100 records for the view
      //
      if (fulllist !== null) {
        return fulllist.slice(startIndex, startIndex + length);
      } else {
        return [];
      }
    }
  }

  const cleanContact = (c: Contact, pmccontacts: PmcContact[]) => {
    // determine initials
    //
    // Use object below for debugging bad contact
    /*c = {
      contactId: "2330",
      emails: [{
        label: "WORK",
        address: "mathew.flesher@fisher-price.com"
      }]
    }
    console.log(c);*/
    let initials = "";
    let sortName = "";
    if (c.name !== null && c.name !== undefined) {
      if (c.name.given !== null && c.name.given !== undefined) {
        initials = c.name.given.substring(0, 1);
      }
      if (c.name.family !== null && c.name.family !== undefined) {
        initials += c.name.family.substring(0, 1);
      }
      if (initials === "" && c.organization !== null) {
        initials = c.organization.company.substring(0, 1);
      }

      // 2023-05-24 mdi added code to protect against nulls.
      //
      if (c.name.family !== null && c.name.family !== undefined) {
        sortName = c.name.family;
      }
      if (sortName !== "") {
        sortName = sortName + ', ';
      }
      if (c.name.given !== null && c.name.given !== undefined) {
        sortName = sortName + c.name.given;
      }
      if (sortName === "" && c.organization.company !== null && c.organization.company !== undefined) {
        sortName = c.organization.company.substring(0, 2);
      }
    } else {
      initials = "  ";
    }

    //  2023-02-12  hmw Update the Loading Text
    //
    setLoadingText(`Loading Contacts ... <br />`);

    // determine phone number
    //
    let phonenumber = "";
    if (c.phones !== null && c.phones !== undefined) {
      let phoneNumbers: PhoneNumber[] = c.phones.filter(n => n.label === 'mobile');
      if (phoneNumbers.length > 0) {
        phonenumber = phoneNumbers[0].number.replace(/\D/g, '');
      } else {
        if (c.phones.length > 0) {
          phonenumber = c.phones[0].number.replace(/\D/g, '');
        }
      }
    }

    // Get Address information
    //
    let address = "";
    if (c.postalAddresses !== null && c.postalAddresses !== undefined) {
      if (c.postalAddresses.length > 0) {
        address = c.postalAddresses[0].street + ', ' + c.postalAddresses[0].city + ' ' + c.postalAddresses[0].postcode;
      }
    }

    // determine email
    //
    let email = "";
    if (c.emails !== null && c.emails !== undefined) {
      if (c.emails.length > 0) {
        email = c.emails[0].address;
      }
    }

    // Check to see if it is already imported
    // imported = true: Means we have a match
    // requiresUpdate = true: Means we have a match but the ExternalID on PMC doesn't match ID from device
    //
    //
    /*
      If we have an external ID, try to match on it. 

      If we do not, and we have an email OR phone number try to match

      If name and phone or name and email match, set external to [firstname]-[email] OR [firstname]-[phone]

      If Organization name  and phone or email match set external to [orgname]-[email] OR [orgname]-[phone]
    */
    let imported = false;
    let requiresUpdate = false;
    let pmcContactID;
    let CalculatedID;

    try {
      // If we have an email or phone
      if (email !== "" || phonenumber !== "") {

        // 2023-05-23 mdi added check to make sure of no null or undefined.
        //console.log("Checking for a match ------------------------------------------");
        CalculatedID = email === "" ? phonenumber : email;

        let namePrefix = "";
        if (c.name !== null && c.name !== undefined) {
          if (c.name.given !== null && c.name.given !== undefined) {
            namePrefix = c.name?.given;
          }
        }

        if (namePrefix === "" && c.organization !== null && c.organization !== undefined) {
          if (c.organization.company !== null && c.organization.company !== undefined) {
            namePrefix = c.organization.company;
          }
        }

        // 2023-05-23 mdi added check to make sure of no null or undefined.
        if (namePrefix !== null && namePrefix !== undefined) {
          if (namePrefix !== "") {
            namePrefix = namePrefix.toLowerCase();
            CalculatedID = namePrefix + '-' + CalculatedID;
          }
        }

        // See if we have a match in pmc contacts
        //
        let matchingContacts: PmcContact[] = pmccontacts.filter(p => p.ExternalID?.toLowerCase() === CalculatedID);
        //console.log("ExternalID: ", matchingContacts);
        if (matchingContacts !== null && matchingContacts !== undefined) {
          if (matchingContacts.length > 0) {
            imported = true;
            requiresUpdate = false;
            pmcContactID = matchingContacts[0].id;
            //console.log("Match on external");
          }
        }

        //console.log("PMC COntact ID:", pmcContactID);
        // if we do not have a match, check on email
        //
        if (pmcContactID === null || pmcContactID === undefined) {
          if (email !== "") {
            // matching email?
            matchingContacts = pmccontacts.filter(p => p.Email?.toLowerCase() === email);
            //console.log("Email: ", matchingContacts);
            if (matchingContacts !== null && matchingContacts !== undefined) {
              if (matchingContacts.length > 0) {
                imported = true;
                pmcContactID = matchingContacts[0].id;
                requiresUpdate = matchingContacts[0].ExternalID?.toLowerCase() === CalculatedID ? false : true;
                //console.log("Match on email");
              }
            }
          }
        }


        // If we do not have a match, check on Phone number
        //
        if (pmcContactID === null || pmcContactID === undefined) {
          if (phonenumber !== "") {
            matchingContacts = pmccontacts.filter(p => p.PhoneNumber?.replace(/\D/g, '') === phonenumber.replace(/\D/g, ''));
            //console.log("Phone: ", matchingContacts);
            if (matchingContacts !== null && matchingContacts !== undefined) {
              if (matchingContacts.length > 0) {
                imported = true;
                pmcContactID = matchingContacts[0].id;
                requiresUpdate = matchingContacts[0].ExternalID?.toLowerCase() === CalculatedID ? false : true;
                //console.log("Match on phone number");
              }
            }
          }
        }


        // If we do not have a match, check on name
        //
        if (pmcContactID === null || pmcContactID === undefined) {
          if (c.name !== null && c.name !== undefined) {
            if (c.name.given !== null && c.name.given !== undefined && c.name.family !== null && c.name.family !== undefined) {
              matchingContacts = pmccontacts.filter(p => p.FirstName?.toLowerCase() === c.name?.given.toLowerCase() && p.LastName?.toLowerCase() === c.name?.family.toLowerCase());
              //console.log("Name: ", matchingContacts);
              if (matchingContacts !== null && matchingContacts !== undefined) {

                if (matchingContacts.length > 0) {
                  imported = true;
                  pmcContactID = matchingContacts[0].id;
                  requiresUpdate = matchingContacts[0].ExternalID?.toLowerCase() === CalculatedID ? false : true;
                  //console.log("Match on name");
                }
              }
            }
          }
        }

      }



      // if it is determined that the contact is imported already (Matched above). Set External ID and make it update
      //
      if (imported) {
        if (matchedContacts !== null && matchedContacts !== undefined) {

          const matches = matchedContacts.filter(m => m.id === pmcContactID);

          if (matches === null || matches === undefined) {

            const pmc = pmccontacts.filter(p => p.id === pmcContactID);
            pmc[0].ExternalID = CalculatedID;
            pmc[0].requiresUpdate = requiresUpdate;
            matchedContacts.push(pmc[0]);
            //console.log("Added 1");
          } else {
            if (matches.length > 0) {
              imported = false;
              pmcContactID = 0;
              requiresUpdate = false;
            } else {

              const pmc = pmccontacts.filter(p => p.id === pmcContactID);
              pmc[0].ExternalID = CalculatedID;
              pmc[0].requiresUpdate = requiresUpdate;
              matchedContacts.push(pmc[0]);
              //console.log("Added 2");
            }
          }
        } else {

          const pmc = pmccontacts.filter(p => p.id === pmcContactID);
          pmc[0].ExternalID = CalculatedID;
          pmc[0].requiresUpdate = requiresUpdate;
          matchedContacts.push(pmc[0]);
          //console.log("Added 3");
        }
      }
      // 2023-05-23 mdi modified logic for display to deal with undefined as well as null
      //
      const cc: CleanContact = {
        contactId: c.contactId,
        displayName: c.name !== null && c.name !== undefined ? c.name.display !== null && c.name.display !== undefined ? c.name.display : c.organization !== null && c.organization !== undefined ? c.organization.company : null : null,
        sortName: sortName,
        initials: initials,
        phoneNumber: phonenumber,
        email: email,
        photo: c.image == null ? `${process.env.PUBLIC_URL}/assets/img/Contact.png` : c.image.base64String,
        address: address,
        imported: imported,
        requiresUpdate: requiresUpdate,
        id: pmcContactID,
        firstName: c.name !== null && c.name !== undefined ? c.name?.given : null,
        lastName: c.name !== null && c.name !== undefined ? c.name?.family : null,
        organizationName: c.organization !== null && c.organization !== undefined ? c.organization?.company : null
      }

      return cc;
    } catch {
      
      logError(JSON.stringify(authInfo.user) + ' | ' + localStorage.getItem("deviceInfo") + '|' + JSON.stringify(c), 'DeviceCOntact');
      return null;
    }
  }

  const handleSelect = (target, contact) => {
    // Set contact into or out of selected list
    //
    if (target.checked) {
      const contactsToAddM = contactsToAdd;
      contactsToAddM.push(contact);
      setImportDisabled(false);

      setContactsToAdd(contactsToAddM);
    } else {
      const removedContact = contactsToAdd;
      const remainingContacts = removedContact.filter(c => c.contactId !== contact.contactId)
      if (remainingContacts.length === 0) {
        setImportDisabled(true);
      }
      //console.log("Array Removed:", remainingContacts);
      setContactsToAdd(remainingContacts);
    }
  }

  const isSelected = (contact: CleanContact) => {
    // Used on render to set selected for checkbox
    //
    const theContact = contactsToAdd.filter(c => c.contactId === contact.contactId);
    if (theContact.length > 0 || contact.imported) {
      return true;
    } else {
      return false;
    }
  }

  const selectAll = () => {
    // Select all or deselect all contacts
    //
    if (SelectLabel === "Select All") {
      const addContacts = ContactFullList;
      //console.log("Selecting:", addContacts.length);
      if (addContacts.length > 0) {
        setImportDisabled(false);
      }
      setContactsToAdd(addContacts);
    } else {
      setImportDisabled(true);
      setContactsToAdd([]);
    }
    setSelectAll(SelectLabel === "Select All" ? "Deselect All" : "Select All");
  }

  const ImportContacts = () => {
    // Import the contacts to PMC
    //
    presentAlert({
      header: "Import contacts",
      message: `Ok to import ${contactsToAdd.length} contact${contactsToAdd.length > 1 ? 's' : ''} into PMC?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Ok',
          role: 'confirm',
          handler: () => {
            let postContacts: PostContact[] = [];
            setShowLoading(true);
            contactsToAdd.forEach(c => {
              let CalculatedID = c.email === "" ? c.phoneNumber : c.email;
              let namePrefix = "" + c.firstName;
              if (namePrefix === "") {
                namePrefix = c.organizationName;
              }
              CalculatedID = namePrefix + '-' + CalculatedID;
              const pc: PostContact = {
                FirstName: c.firstName,
                LastName: c.lastName,
                Email: c.email,
                PhoneNumber: c.phoneNumber,
                ExternalId: CalculatedID
              }
              postContacts.push(pc);
            });
            const postContactList: PostContactList = {
              Contacts: postContacts
            };
            //console.log(postContactList);
            // Post list to Server
            //
            addContacts(access_token, postContactList).then((response) => {

              setImportDisabled(true);
              setSelectAll("Select All");
              //setContactsToAdd([]);
              setShowLoading(false);
              // Display message
              //
              ShowConfirmation(response);
            }).catch(error => {
              setShowLoading(false);
            })
          }
        }
      ]
    });

  }

  const ShowConfirmation = (response) => {
    // Update interface to match
    //
    const importedContacts = contacts;
    contactsToAdd.forEach(c => {
      const theContact = importedContacts.filter(i => i.contactId === c.contactId);
      theContact[0].imported = true;
    });
    setContacts(importedContacts);
    setContactsToAdd([]);

    // Display message
    //
    presentDoneAlert({
      header: 'Contacts Imported',
      message: `Contacts Added: ${response.Added}<br/>Contacts Updated: ${response.Updated}<br/>Contacts Skipped: ${response.Skipped}`,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            forceUpdate();
          }
        }
      ]
    });

  }

  useEffect(() => {
    loadContactsDevice();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setContacts([]);
    setContacts(getContacts(ContactFullList, 100, 0));
    forceUpdate();

    // eslint-disable-next-line
  }, [searchText]);

  return (
    <IonPage className='ContactList'>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref='/tab/Home' />
          </IonButtons>
          <IonTitle>Import Contacts</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar color="light" show-clear-button="focus" ref={searchBar} debounce={800} placeholder="Search" onIonChange={s => handleSearchChange(s.detail.value!)}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          {contacts.map((c, index) => (<IonItem detail={false} key={c.contactId}>
            <IonGrid>
              <IonRow>
                <IonCol size='2'><IonCheckbox checked={isSelected(c)} disabled={c.imported} onClick={(e) => handleSelect(e.target, c)}></IonCheckbox></IonCol>
                <IonCol size='2'>
                  <IonAvatar>
                    <img src={c.photo} alt={c.initials} />
                  </IonAvatar>
                </IonCol>
                <IonCol size='8' className='nameColumn'>
                  <IonLabel className="ion-text-wrap">
                    <div className='name'>{c.displayName}</div>
                    <div className='email'>{c.address}</div>
                    <div className='phone'>{c.phoneNumber}</div>
                    <div className='email'>{c.email}</div>
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>


          </IonItem>))}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            loadMore();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton color='light' slot='start' onClick={() => selectAll()} disabled={contacts === null || contacts?.length === 0}>{SelectLabel}</IonButton>
          <IonButton color='light' ref={importButton} slot='end' disabled={importDisabled} onClick={() => ImportContacts()}>Import</IonButton>
        </IonToolbar>
      </IonFooter>
      <IonLoading
        isOpen={showLoading}
        message={loadingText}
      />
    </IonPage>
  )
};

export default ContactsList;