/*
// NotificationListener ------
// This component is used to set up the notification listener. It handles the notifications coming in if there is data.
// This is written as a component so that we can use the Ion Alert to prompt them about the notification and ask them to
// send a thank you. 
//
// pushNotificationActionPerformed - This is fired when app is not in forground and notification is tapped
// pushNotificationReceived - This is fired when app is in foreground and notification comes in
//
*/

import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
import { useIonAlert } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { EmailComposer } from 'capacitor-email-composer';
import { SMS } from '@awesome-cordova-plugins/sms'
import { setThanked } from '../Common/api';
import { Thanked } from '../Models/api';
import { useAuth } from '../Common/authContext';
import EventContext, { EventContextType, LoadSummaryData } from '../Common/EventContext';
import SettingsContext from '../Common/SettingsContext';
import { Capacitor } from '@capacitor/core';

const NotificationListener: React.FC = () => {
    const [presentAlert] = useIonAlert();
    const { authInfo } = useAuth()!;
    const access_token = authInfo.user.access_token;
    const [listenerAdded, setListenerAdded] = useState(false);

    //  Event Context Values
    //
    const { eventData, setEventData } = useContext(EventContext);
    let { summaryData } = eventData;

    //  Settings Context values
    //
    const { data } = useContext(SettingsContext);
    const { CurrentEvent } = data;
    const event = CurrentEvent;

    // ShowAlert - Called to fire the alert
    //
    const showAlert = (data: any, buttons: any) => {
        presentAlert({
            header: data.header,
            message: data.message,
            backdropDismiss: false,
            buttons: buttons
        });
    }

    const setThankedRemote = (type: string, data: any) => {
        const thanked: Thanked = {
            ThankType: type,
            UniqueID: data.UniqueID
        }

        setThanked(thanked, access_token);
    }
    // SendEmailThankYou - Starts the email message to donor
    //
    const sendEmailThankYou = (data: any) => {
        EmailComposer.open({
            to: [data.email],
            body: data.body,
            subject: data.subject,
            isHtml: true
        });
        setThankedRemote('Email', data);
    }

    // SendTextThankYou - Starts the text message to the donor
    //
    const sendTextThankYou = (data: any) => {
        SMS.send(data.phone, data.text);
        setThankedRemote('Text', data);
    }

    // HandleNotificationData - If the notification has data, this is called.
    //
    const handleNotificationData = async (data: any) => {
        const dta = data;
        //console.log("Raw Data:", dta);
    
        // Parse the data
        //
        const parsedData: any = {
        };
        Object.keys(dta).map(key => {
            if (key.toString() === "type") {
                parsedData.type = dta[key];
            }
            if (key.toString() === "header") {
                parsedData.header = dta[key];
            }
            if (key.toString() === "message") {
                parsedData.message = dta[key];
            }
            if (key.toString() === 'email') {
                parsedData.email = dta[key];
            }
            if (key.toString() === 'phone') {
                parsedData.phone = dta[key];
            }
            if (key.toString() === 'UniqueID') {
                parsedData.UniqueID = dta[key];
            }
            if (key.toString() === 'subject') {
                parsedData.subject = dta[key];
            }
            if (key.toString() === 'body') {
                parsedData.body = dta[key];
            }
            if (key.toString() === 'text') {
                parsedData.text = dta[key];
            }
            if (key.toString() === 'eventname') {
                parsedData.eventname = dta[key];
            }
            if (key.toString() === 'amount') {
                parsedData.amount = dta[key];
            }
            return null;
        });

        
        // if type is donation
        // - Present alert with buttons
        //
        if (parsedData.type === 'donation') {
            // Increase Raised
            //
            let newData = summaryData;
            let raised = summaryData.Raised;
            let lifeTime = summaryData.TotalRaised;
            let amt = parsedData.amount*1;
            raised += amt;
            lifeTime += amt;
            newData.Raised = raised;
            newData.TotalRaised = lifeTime;
            
            setEventData({ Events: summaryData.EventList, TeamName: summaryData.TeamName, IsTeamCaptain: summaryData.IsCaptain, summaryData: newData });
            // Show alert with buttons for thank you by email, text and close.
            //
            let btns = [{
                text: 'Thank via email',
                role: 'confirm',
                handler: () => {
                    sendEmailThankYou(parsedData);
                }
            },
            {
                text: 'Thank via text',
                role: 'confirm',
                handler: () => {
                    sendTextThankYou(parsedData);
                }
            },
            {
                text: 'Close',
                role: 'cancel'
            }];

            try {
                showAlert(parsedData, btns);

            } catch (error) {
                console.log(error);
            }
        }

        // if type is eventsummary
        // - update summary data if it matches current event
        //
        if (parsedData.type === 'history') {
            //console.log("History Notification received for " + parsedData.eventname.toLowerCase())
            if(parsedData.eventname.toLowerCase()===event.EventName){
                const theSummary = await LoadSummaryData(event.EventName, authInfo.user.Main_ID, authInfo.user.access_token);
                setEventData({ Events: theSummary.EventList, TeamName: theSummary.TeamName, IsTeamCaptain: theSummary.IsCaptain, summaryData: theSummary });
            }
        }
    }

    // addNotificationListener - Called to add the listeners
    // pushNotificationActionPerformed - This is fired when app is not in forground and notification is tapped
    // pushNotificationReceived - This is fired when app is in foreground and notification comes in
    //
    const addNotificationReceivedListener = async (eventData: EventContextType) => {
        setEventData(eventData);

        // Only use notifications if available (Not available on the web)
        //
        if(Capacitor.isPluginAvailable("PushNotifications")){

            PushNotifications.addListener(
                'pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    //alert('Push action performed: ' + JSON.stringify(notification));
                    if (notification.notification.data) {
                        handleNotificationData(notification.notification.data);
                    }
                },
            );
            PushNotifications.addListener(
                'pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    //console.log(JSON.stringify(notification));
                    //alert('Push received: ' + JSON.stringify(notification));
                    if (notification.data) {
                        handleNotificationData(notification.data);
                    }
                });

            await FirebaseMessaging.addListener('notificationReceived', event => {
                if (event.notification.data) {
                    handleNotificationData(event.notification.data);
                }
            });
        }   
        
        setListenerAdded(true);
        
    }

    useEffect(() => {
        
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!listenerAdded && eventData.summaryData.EventList!==''){
            addNotificationReceivedListener(eventData);
        }
    // eslint-disable-next-line
    }, [eventData]);

    return (
        <></>
    );
};

export default NotificationListener;