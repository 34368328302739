import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';

import './Privacy.css';

const Privacy: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref='/tab/Home' />
                    </IonButtons>
                    <IonTitle>Privacy Policy</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <div className='about-container'>
                    <p>Our Privacy Policy is designed to assist you in understanding how we collect, use and safeguard the personal information you provide us and to assist you in making informed decisions when using our site.</p>

                    <h1>Confidentiality</h1>
                    <p>You may use our site without disclosing personally identifiable information. We want to be clear that we will not obtain personal information about you unless you choose to submit it to us.</p>

                    <h1>Information Collection & Use</h1>
                    <p>The PMC site uses cookies to track traffic coming to our site. We do not collect personal data for this purpose, such as name, age, phone number, e-mail, or mailing address. Cookies will be used to identify a person who chooses to register on our site. This cookie-based information will be used to provide you with information appropriate to your interests when you visit our site (rider, volunteer, sponsor, etc.) You will always have a chance to opt out at any time from accepting cookies by adjusting the settings in your web browser.</p>

                    <p>If you supply us with personally identifiable information (such as name, address or telephone numbers), it will be used only for the purpose for which it was collected. For example, if you provide us with information (such as your name, contact details, and credit card) as part of an online donation, we will not use that information for any other purpose beyond administering, recording, and acknowledging the donation. The PMC acknowledges gifts of $250 or more by letter. Your home address will be used only for this purpose. Individuals who register for a PMC event will also receive emails containing event information and updates. The PMC will not sell, share, or rent your personally identifiable information to others, although, we may share information with third parties who help us run PMC and our activities. Note, however, that information you choose to provide to create a personal web page on our web site will be accessible through the personal web page to prospective donors and others. We may also share information if required to do so by legal process, or if we transfer our operations to another entity, or in order to reasonably protect our site and PMC generally.</p>

                    <p>You may request deletion of your data by sending us an email at <a href="mailto:panmass@pmc.org">panmass@pmc.org</a>  and we will remove your personal information.</p>

                    <h1>Security</h1>
                    <p>The PMC takes seriously the protection of our users’ information and we take reasonable precautions to protect your information once you have provided it to us. For example, when a registration/eGift form asks users to enter sensitive information (such as credit card number), that information is encrypted between your browser and our computers. However, even with the best intentions, the Internet is an inherently open system and the PMC cannot guarantee the security of any information that you provide.</p>

                    <h1>Children</h1>
                    <p>Our web site is not intended for use by children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. With respect to the web pages concerning the PMC Kids’ Rides, please see our PMC Kids Privacy Policy, which describes how we may collect information from parents about their children.</p>

                    <h1>PMC App</h1>
                    <p>When installed, the Pan-Mass Challenge (PMC) app requests permission to upload your phone’s contacts to the PMC’s secure servers solely for your use to fundraise. The PMC will not access or use your contacts for any other purpose. The PMC never communicates with or shares your contacts nor do we share that information with any of our affiliates. Your contact’s information is stored on our secure servers until you request that we remove it. You may click here to email your request.</p>

                    <h1>Policy Changes</h1>
                    <p>From time to time, we may use personal information for unanticipated uses not previously disclosed in our privacy notice. If our information practices change, we will post these changes on our web site. We encourage you to review our privacy policy periodically.</p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Privacy;
