
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
} from "@ionic/react";
import { Redirect, Route } from "react-router";
import React, { useContext, useEffect } from "react";
import { person, home, barChart, logoUsd, camera } from "ionicons/icons";
import { Device } from '@capacitor/device';

//  Views
//
//import TabsRouterOutlet from "../components/router/TabsRouterOutlet";
import HomePage from "./HomePage";
import DonationsPage from "./DonationsPage";
import FundraisingPage from "./FundraisingPage";
import MobileDepositPage from "./MobileDepositPage";
import ProfilePage from "./ProfilePage";

//  Contexts
//
import SettingsContext from "../Common/SettingsContext";

const TabsRootPage: React.FC = () => {
  //  Settings Context Values
  //
  const { data } = useContext(SettingsContext);
  const { ViewAs, MobileDeposit } = data;

  const getDeviceInfo = async () => {
    let id = await Device.getId();
    const did = id.identifier;
    localStorage.setItem("deviceId", did);
    let deviceData = await Device.getInfo();
    const DeviceInfo = {
        os: deviceData.operatingSystem,
        osVersion: deviceData.osVersion,
        name: deviceData.name,
        model: deviceData.model
    }
    localStorage.setItem("deviceInfo", JSON.stringify(DeviceInfo));
  }

  const ProfileLabel = ViewAs === 'team' ? 'Team Profile' : 'Profile';
  const DonationsLabel = ViewAs === 'team' ? 'Team Donations' : 'Donations';

  useEffect(() => {
    getDeviceInfo();
  }, []);

  return (
    <IonTabs>
      {/* <TabsRouterOutlet /> */}

      <IonRouterOutlet>
        <Route path="/tab" exact={true}>
          <Redirect to="/tab/home" />
        </Route>

        <Route path="/tab/home">
          <HomePage />
        </Route>

        <Route path="/tab/profile">
          <ProfilePage />
        </Route>

        <Route path="/tab/fundraising">
          <FundraisingPage />
        </Route>

        <Route path="/tab/donations">
          <DonationsPage />
        </Route>

        <Route path="/tab/deposit">
          <MobileDepositPage />
        </Route>

      </IonRouterOutlet>

      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/tab/home" >
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>

        <IonTabButton tab="profile" href="/tab/profile" >
          <IonIcon icon={person} />
          <IonLabel>{ProfileLabel}</IonLabel>
        </IonTabButton>

        {ViewAs !== 'team' &&
          <IonTabButton tab="fundraising" href="/tab/fundraising" >
            <IonIcon icon={barChart} />
            <IonLabel>Fundraising</IonLabel>
          </IonTabButton>
        }

        <IonTabButton tab="donations" href="/tab/donations" >
          <IonIcon icon={logoUsd} />
          <IonLabel>{DonationsLabel}</IonLabel>
        </IonTabButton>

        {MobileDeposit && ( 
        <IonTabButton tab="deposit" href="/tab/deposit">
          <IonIcon icon={camera} />
          <IonLabel>Deposit</IonLabel>
        </IonTabButton>
        )} 

      </IonTabBar>

    </IonTabs>
  );
};

export default TabsRootPage;
