import { SetStateAction, useState } from "react";
import axios from "axios";

import { logErrorUrl, version } from "./Global";
import { errorObj } from "../Models/error";

export const useFormInput = (initialValue = "") => {

	const [value, setValue] = useState(initialValue);

	const handleChange = async (e: { currentTarget: { value: any; }; }) => {

		const tempValue = await e.currentTarget.value;
		setValue(tempValue);
	}

	return {

		value,
		reset: (newValue: SetStateAction<string>) => setValue(newValue),
		onIonChange: handleChange,
		onKeyUp: handleChange
	};
}

export const validateForm = (fields: any[]) => {

	let errors: { id: any; message: string; }[] = [];

	fields.forEach((field: { required: any; input: { state: { value: any; }; }; id: any; }) => {

		if (field.required) {

			const fieldValue = field.input.state.value;

			if (fieldValue === "") {

				const error = {

					id: field.id,
					message: `Please check your ${field.id}`,
				};

				errors.push(error);
			}
		}
	});

	return errors;
}

export const currencyFormat = (num: number) => {
	try {
		return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	} catch {
		return '$0';
	}
}

export const logError = (message: string, something: string) => {
	let logUrl = logErrorUrl + something;
	
	const theError: errorObj = {
		version: version,
		message: message,
		auth: localStorage.getItem("AUTH"),
		deviceInfo: localStorage.getItem("deviceInfo")
	}
	let data = '=' + JSON.stringify(theError);
	
	axios.post(logUrl, data).catch(error => {
		console.log("An error occurred logging the error.");
	});
}

export const encodePass = (password: string) => {
	let encodedPass = password.replaceAll('&', '%26');

	return encodedPass;
}