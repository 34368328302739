import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonList, IonItem, IonIcon, IonLabel, IonAvatar, IonItemDivider, IonItemGroup, IonToggle } from '@ionic/react';
import { useContext, useRef } from 'react';

import {
  peopleCircleOutline, peopleCircleSharp,
  logOutOutline, logOutSharp,
  checkmark,
  moonOutline, moonSharp
} from 'ionicons/icons';

import SettingsContext, { EventList } from '../Common/SettingsContext';
import EventContext from '../Common/EventContext';

import { useAuth } from '../Common/authContext';
import { getMenu } from '../Data/menu';

export interface SettingsProps {
  promptToRegister: any;
}

const SettingsModal: React.FC<SettingsProps> = (props) => {


  const menuItems = getMenu().filter(m => m.position === 'settings');
  const modal = useRef<HTMLIonModalElement>(null);

  const { data, setData } = useContext(SettingsContext);
  const { ViewAs, CurrentEvent, Theme, MobileDeposit } = data;

  //  Event Context Values
  //
  const { eventData } = useContext(EventContext);
  const { Events, TeamName, IsTeamCaptain, summaryData } = eventData;

  const { authInfo } = useAuth()!;
  const ProfileName = authInfo.user.ProfileName;

  let { logOut } = useAuth()!;

  //  TODO: Determine how to set individual values without passing entire data object
  //
  const toggleViewAs = () => {
    setData({ CurrentEvent, ViewAs: ViewAs === 'self' ? 'team' : 'self', Theme, MobileDeposit });
  }

  const setCurrentEvent = (value) => {
    // MDI - made viewas go to self so if they are viewing as the team and switch they are ok if they are not on a team in other event.
    // 2024-01-02 mdi modified this so PMC will not show dialog if not registered.
    //if (Events.includes(value.EventName)) {
    if (Events.includes(value.EventName) || value.EventName === 'pmc') {
      setData({ CurrentEvent: value, ViewAs: 'self', Theme, MobileDeposit });
    } else {
      props.promptToRegister(value);
    }
  }

  //  2023-01-29  hmw Added Toggle Dark Mode
  //
  const toggleDarkModeHandler = () => {
    const theme = Theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', theme);
    setData({ CurrentEvent, ViewAs, Theme: theme, MobileDeposit });

    document.body.classList.toggle('dark');
  }

  //const toggleDarkModeHandler = () => document.body.classList.toggle('dark');

  return (
    <IonModal ref={modal} trigger="open-settings-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => modal.current?.dismiss()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">

        <IonItemGroup>

          <IonList>
            {menuItems.map((menuItem, index) => {
              return (
                <IonItem key={index} routerLink={menuItem.url} detail={false} onClick={() => modal.current?.dismiss()} lines='none' >
                  <IonIcon slot="start" ios={menuItem.iosIcon} md={menuItem.mdIcon} />
                  <IonLabel><h2>{menuItem.title}</h2></IonLabel>
                </IonItem>
              );
            })}

            {IsTeamCaptain &&
              <IonItem key='viewas' onClick={() => { toggleViewAs(); modal.current?.dismiss(); }} lines='none'>
                <IonIcon slot="start" ios={peopleCircleOutline} md={peopleCircleSharp} />
                <IonLabel><h2>View as {ViewAs !== 'self' ? ProfileName : TeamName}</h2></IonLabel>
              </IonItem>
            }
          </IonList>
        </IonItemGroup>

        <IonItemGroup>
          <IonItemDivider />
          <IonList>
            <IonItem key='toggletheme' lines='none'>
              <IonIcon slot='start' ios={moonOutline} md={moonSharp} />
              <IonLabel>Toggle Dark Mode</IonLabel>
              <IonToggle
                slot='end'
                name='darkMode'
                checked={Theme === 'dark'}
                onIonChange={toggleDarkModeHandler}
              />
            </IonItem>
          </IonList>
        </IonItemGroup>

        <IonItemGroup>
          <IonItemDivider />
          <IonList>
            {/* <IonItem key='pmc' onClick={() => { event.setEvent(EventList.pmc); modal.current?.dismiss() }} > */}
            <IonItem key='pmc' onClick={() => { setCurrentEvent(EventList.pmc); modal.current?.dismiss() }} lines='none'>
              <IonAvatar slot="start">
                <img src={process.env.PUBLIC_URL + '/assets/img/badges/pmc.png'} alt="PMC Event" />
              </IonAvatar>
              <IonLabel>
                <h2>PMC {summaryData.RegisteredEvents.includes('pmc') && ' (Registered)'}</h2>
              </IonLabel>
              {CurrentEvent.EventName === 'pmc' &&
                <IonIcon slot="end" icon={checkmark} />
              }
            </IonItem>

            {EventList.unpaved.Enabled &&
              <IonItem key='unpaved' onClick={() => { setCurrentEvent(EventList.unpaved); modal.current?.dismiss() }} lines='none'>
                <IonAvatar slot="start">
                  <img src={process.env.PUBLIC_URL + '/assets/img/badges/unpaved.png'} alt="Unpaved Event" />
                </IonAvatar>
                <IonLabel>
                  <h2>Unpaved {summaryData.RegisteredEvents.includes('unpaved') && ' (Registered)'}</h2>
                </IonLabel>
                {CurrentEvent.EventName === 'unpaved' &&
                  <IonIcon slot="end" icon={checkmark} />
                }
              </IonItem>
            }

            {EventList.wintercycle.Enabled &&
              <IonItem key='wintercycle' onClick={() => { setCurrentEvent(EventList.wintercycle); modal.current?.dismiss() }} lines='none'>
                <IonAvatar slot="start">
                  <img src={process.env.PUBLIC_URL + '/assets/img/badges/wintercycle.png'} alt="Winter Cycle Event" />
                </IonAvatar>
                <IonLabel>
                  <h2>Winter Cycle {summaryData.RegisteredEvents.includes('winter-cycle-boston') && ' (Registered)'}</h2>
                </IonLabel>
                {CurrentEvent.EventName === 'wintercycle' &&
                  <IonIcon slot="end" icon={checkmark} />
                }
              </IonItem>
            }

          </IonList>
        </IonItemGroup>

        <IonItemGroup>
          <IonItemDivider>
          </IonItemDivider>
          <IonList>
            <IonItem key='logout' onClick={() => { logOut(CurrentEvent.SecureUrl); modal.current?.dismiss(); }} lines='none'>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel><h2>Logout</h2></IonLabel>
            </IonItem>
          </IonList>
        </IonItemGroup>
      </IonContent>
    </IonModal>

  );
};

export default SettingsModal;

